// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-pages-accounts-index-jsx": () => import("./../../../src/pages/accounts/index.jsx" /* webpackChunkName: "component---src-pages-accounts-index-jsx" */),
  "component---src-pages-accounts-new-jsx": () => import("./../../../src/pages/accounts/new.jsx" /* webpackChunkName: "component---src-pages-accounts-new-jsx" */),
  "component---src-pages-backup-csv-backup-jsx": () => import("./../../../src/pages/backup/CSVBackup.jsx" /* webpackChunkName: "component---src-pages-backup-csv-backup-jsx" */),
  "component---src-pages-backup-index-jsx": () => import("./../../../src/pages/backup/index.jsx" /* webpackChunkName: "component---src-pages-backup-index-jsx" */),
  "component---src-pages-clients-index-jsx": () => import("./../../../src/pages/clients/index.jsx" /* webpackChunkName: "component---src-pages-clients-index-jsx" */),
  "component---src-pages-incidents-index-jsx": () => import("./../../../src/pages/incidents/index.jsx" /* webpackChunkName: "component---src-pages-incidents-index-jsx" */),
  "component---src-pages-index-jsx": () => import("./../../../src/pages/index.jsx" /* webpackChunkName: "component---src-pages-index-jsx" */),
  "component---src-pages-logs-index-jsx": () => import("./../../../src/pages/logs/index.jsx" /* webpackChunkName: "component---src-pages-logs-index-jsx" */),
  "component---src-pages-overview-jsx": () => import("./../../../src/pages/overview.jsx" /* webpackChunkName: "component---src-pages-overview-jsx" */),
  "component---src-pages-password-jsx": () => import("./../../../src/pages/password.jsx" /* webpackChunkName: "component---src-pages-password-jsx" */),
  "component---src-pages-report-graphs-jsx": () => import("./../../../src/pages/report/graphs.jsx" /* webpackChunkName: "component---src-pages-report-graphs-jsx" */),
  "component---src-pages-sessions-index-jsx": () => import("./../../../src/pages/sessions/index.jsx" /* webpackChunkName: "component---src-pages-sessions-index-jsx" */),
  "component---src-pages-victim-incident-jsx": () => import("./../../../src/pages/victim/Incident.jsx" /* webpackChunkName: "component---src-pages-victim-incident-jsx" */),
  "component---src-pages-victim-index-jsx": () => import("./../../../src/pages/victim/index.jsx" /* webpackChunkName: "component---src-pages-victim-index-jsx" */),
  "component---src-pages-victim-new-incident-jsx": () => import("./../../../src/pages/victim/newIncident.jsx" /* webpackChunkName: "component---src-pages-victim-new-incident-jsx" */),
  "component---src-pages-victim-new-session-jsx": () => import("./../../../src/pages/victim/newSession.jsx" /* webpackChunkName: "component---src-pages-victim-new-session-jsx" */),
  "component---src-pages-victim-new-victim-jsx": () => import("./../../../src/pages/victim/NewVictim.jsx" /* webpackChunkName: "component---src-pages-victim-new-victim-jsx" */),
  "component---src-pages-victim-one-victim-jsx": () => import("./../../../src/pages/victim/OneVictim.jsx" /* webpackChunkName: "component---src-pages-victim-one-victim-jsx" */),
  "component---src-pages-victim-session-jsx": () => import("./../../../src/pages/victim/Session.jsx" /* webpackChunkName: "component---src-pages-victim-session-jsx" */)
}

